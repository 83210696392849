import React, {Fragment, useEffect, useRef, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./ResetSessionPasswordForm.module.scss";
import {
    Alert,
    AlertActionCloseButton,
    Button,
    Grid,
    GridItem,
    Skeleton,
    Spinner,
    Text,
    Title
} from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import {UPDATE_SESSION_PASSWORD} from "services/urls";
import {InputErrors} from "components";
import {EyeIcon} from "@patternfly/react-icons";

type Message = {
    variant: "success" | "danger" | "default" | "warning" | "info" | undefined
    message: string
} | undefined

const ResetSessionPasswordForm: React.FC = () => {

    const accessToken = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const [ fetching, setFetching ] = useState<boolean>(true);
    const [ message, setMessage ] = useState<Message>();
    const passwordRef = useRef<any>();
    const currentPasswordRef = useRef<any>();
    const confirmPasswordRef = useRef<any>();
    const formRef = useRef<any>();

    const required = value => (value ? undefined : value);

    const resetForm = (form) => {
        // @ts-ignore
        Array.from(form.elements).forEach((input) => input.value = '');
    }

    const handleSubmit = (values, e) => {
        setFetching(true)
        fetch(UPDATE_SESSION_PASSWORD, {
            method : "PATCH",
            headers : {
                "x-access-token" : String(accessToken),
                "Content-Type" : "application/json",
            },
            body : JSON.stringify(values)
        })
            .then((response) => response.status)
            .then(async (data) => {
                data === 204 ?
                    setMessage({
                        "variant" : "success",
                        "message" : "Le mot de passe du compte a été mis à jour avec succès!"
                    }) :
                    data === 412 ?
                        setMessage({
                            "variant" : "danger",
                            "message" : "Le mot de passe actuel fourni n'est pas valide"
                        }) :
                        setMessage({
                            "variant" : "danger",
                            "message" : "Oop, nous sommes désolés erreur de serveur échouée par l'opérateur, réessayez si cela persiste, veuillez contacter le support technique!"
                        })
            }).finally(() => {
            setFetching(false)
            e.reset()
            resetForm(formRef.current)
        })
    }

    useEffect(() => {
        accessToken && setFetching(false)
    }, [ accessToken ])

    const togglePasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    const toggleConfirmPasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    const toggleCurrentPasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    return (
        <Fragment>
            <FinalForm
                onSubmit={handleSubmit}
                subscription={{
                    submitting : true,
                }}
                initialValues={{
                    // current_password : "",
                    password : "",
                    confirm_password : ""
                }}
                mutators={{
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors["password"] = "Ce champ est obligatoire";
                    }
                    // if (!values.current_password) {
                    //     errors["current_password"] = "Ce champ est obligatoire";
                    // }
                    if (!values.confirm_password) {
                        errors["confirm_password"] = "Ce champ est obligatoire";
                    }
                    if (values.confirm_password !== values.password) {
                        errors["confirm_password"] = "Les champs du mot de passe doivent correspondre";
                    }
                    if (values.current_password === values.password) {
                        errors["password"] = "Le mot de passe doit être différent du mot de passe actuel";
                    }
                    return errors;
                }}
            >
                {({
                      handleSubmit,
                  }) => {

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className={_css.container}
                            ref={formRef}
                        >
                            <Grid hasGutter>
                                <GridItem>
                                    <Title headingLevel={"h3"} size="3xl">
                                        Réinitialiser le mot de passe
                                    </Title>
                                    <GridItem lg={10} sm={12}>
                                        <Text>
                                            Réinitialisez le mot de passe de votre compte dans l'espace de champs donné.
                                        </Text>
                                    </GridItem>
                                </GridItem>
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="password"
                                        validate={required}
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <label htmlFor="password">
                                                <span className={_css.labels}>Mot de passe</span>
                                                <div className={_css.password_box}>
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="password"
                                                        id="password"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        ref={passwordRef}
                                                        name={input.name}
                                                        placeholder={"Mot de passe"}
                                                    />
                                                    <EyeIcon onClick={() => togglePasswordDisplay(passwordRef)}/>
                                                </div>
                                                {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="confirm_password"
                                        validate={required}
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <label htmlFor="confirm_password">
                                                <span className={_css.labels}>Confirmez le mot de passe</span>
                                                <div className={_css.password_box}>
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="password"
                                                        id="confirm_password"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        ref={confirmPasswordRef}
                                                        placeholder={"Confirmez le mot de passe"}
                                                    />
                                                    <EyeIcon
                                                        onClick={() => toggleConfirmPasswordDisplay(confirmPasswordRef)}/>
                                                </div>
                                                {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>

                                {(!fetching && message) &&
                                <GridItem>
                                    <Alert isInline variant={message.variant || "default"}
                                           title={"Mise à jour du compte"}
                                           actionClose={<AlertActionCloseButton
                                               onClose={() => setMessage(undefined)}/>}>
                                        <Text>
                                            {message.message}
                                        </Text>
                                    </Alert>
                                </GridItem>}
                                <GridItem>
                                    <Button isDisabled={fetching} type={"submit"}>
                                        {fetching ? <Spinner size="sm"/> : <span>Mettre à jour</span>}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </form>
                    )
                }}
            </FinalForm>
        </Fragment>
    )
}

export default ResetSessionPasswordForm;
